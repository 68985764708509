import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ProjectDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5em;
  border-right: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  border-left: 1px solid var(--color-border);
  border-top: 0.5em solid var(--color-primary);
  box-shadow: var(--box-shadow);
  background: var(--color-background-offset);
  padding: 4em;
  margin: 2em 0;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    padding: 2em;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 1em;

  @media screen and (max-width: 960px) {
    width: 100%;
    margin-bottom: 1em;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const Heading = styled.p`
  font-size: 1em;
  font-weight: 600;
`;

export const Text = styled.div`
  font-size: 0.85em;
  font-weight: 300;
`;

const DemoButton = styled(Button)`
  display: flex;
  padding: 0.75em 1em 0.75em 1.25em;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background: var(--color-primary);
  color: var(--color-background);
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin-top: 1.5em;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`;
const CardArrow = styled(FontAwesomeIcon)`
  color: var(--color-background);
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin: 0 0 0 ${({ theme }) => theme.spacing['1']};
`;

export const DetailSection = styled.div`
  margin-bottom: 1.5em;

  @media screen and (max-width: 960px) {
    margin-bottom: 1em;
  }
`;

export function ProjectDetails({
	excerpt, demoUrl, tools, role, date,
}) {
	return (
		<ProjectDetailsContainer>
			<LeftColumn>
				<Heading>About Project</Heading>
				<Text>{excerpt}</Text>
				{demoUrl && (
					<DemoButton
						href={demoUrl}
						target="_blank"
						rel="noopener noreferrer"
					>
						View Demo
						{' '}
						<CardArrow icon="circle-right" />
					</DemoButton>
				)}
			</LeftColumn>
			<RightColumn>
				{tools.length > 0 && (
					<DetailSection>
						<Heading>Tools Used</Heading>
						{tools.map((tool, index) => (
							<a href={tool.link} target="_blank" rel="noreferrer" key={tool.id}>
								<img
									src={tool.logo.file.url}
									alt={tool.name}
									width="40px"
									height="40px"
									style={{
										borderRadius: '50em',
										marginTop: '0.25em',
										marginRight: '0.25em',
										border: '1px solid var(--color-border)',
									}}
								/>
							</a>
						))}
					</DetailSection>
				)}
				{role && (
					<DetailSection>
						<Heading>Role</Heading>
						<div>
							<Text>{role}</Text>
						</div>
					</DetailSection>
				)}
				{date && (
					<DetailSection>
						<Heading>Date</Heading>
						<div>
							<Text>{date}</Text>
						</div>
					</DetailSection>
				)}
			</RightColumn>
		</ProjectDetailsContainer>
	);
}

ProjectDetails.defaultProps = {
	excerpt: '',
	tools: [],
	role: '',
	date: '',
};

ProjectDetails.propTypes = {
	excerpt: PropTypes.string,
	tools: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		link: PropTypes.string,
		name: PropTypes.string,
		logo: PropTypes.shape({
			file: PropTypes.shape({
				url: PropTypes.string,
			}),
		}),
	})),
	role: PropTypes.string,
	date: PropTypes.string,
};
