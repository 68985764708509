import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { Location } from '@reach/router';

import {
	// Banner,
	Navbar,
} from '../../components';

export const NavbarContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  top: -1px;
  z-index: 2;
`;
function Header({ siteTitle, toggleThemePicker }) {
	return (
		<NavbarContainer>
			<Navbar title={siteTitle} toggleThemePicker={toggleThemePicker} />
			{/* <Location>
				{({ location }) =>
					location.pathname.startsWith('/work') && (
						<Banner
							message="Looking for my certifications?"
							link="/certifications"
							linkMessage="View Certifications"
							arrow
						/>
					)}
			</Location>
			<Location>
				{({ location }) =>
					location.pathname.startsWith('/contact') && (
						<Banner
							message="Looking to meet virtually?"
							link="/schedule"
							linkMessage="Schedule a Call"
							arrow
						/>
					)}
			</Location> */}
		</NavbarContainer>
	);
}

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: '',
};

export default Header;
