import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Card } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeaturedCardContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
  margin-bottom: 4em;
  border-top: 1px solid var(--color-border);
`;

const CardGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`;

const ViewMoreCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: ${props => props.theme.fontSize.xl};
  color: var(--color-text);
  border: 1px solid var(--color-border);
  border-radius: ${props => props.theme.borderRadius.default};

  :hover {
    box-shadow: var(--box-shadow);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);

    svg {
      color: var(--color-primary);
    }
  }
`;

const FeaturedWorkHeading = styled.p`
  margin-bottom: 1.5em;
  font-size: ${props => props.theme.fontSize['2xl']};
  color: var(--color-text);
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  color: var(--color-text);
  font-size: ${({ theme }) => theme.fontSize.xl};
  margin: 0 ${({ theme }) => theme.spacing['2']};
`;

export function FeaturedCards({ title, featured }) {
	return featured && (
		<FeaturedCardContainer>
			<FeaturedWorkHeading>{title || 'Featured'}</FeaturedWorkHeading>
			<CardGroup>
				{featured.map(item => (
					<Link
						to={`/work/${item.client.slug}/${item.slug}`}
						key={item.id}
						style={{ textDecoration: 'none', width: '100%' }}
					>
						<Card
							title={item.projectTitle || 'Project Title'}
							description={
								item.projectExcerpt.projectExcerpt || 'Project Description'
							}
							label={item.client.clientName}
						/>
					</Link>
				))}
				<Link to="/work" style={{ textDecoration: 'none', width: '100%' }}>
					<ViewMoreCard>
						<span style={{ padding: '1em 0em' }}>
							View More
							<ArrowIcon icon="circle-right" />
						</span>
					</ViewMoreCard>
				</Link>
			</CardGroup>
		</FeaturedCardContainer>
	);
}
