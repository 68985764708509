import { config } from '@fortawesome/fontawesome-svg-core';

import {
	wrapRootElement as wrapRoot,
	wrapPageElement as wrapPage,
} from './src/helpers/utils/root-wrapper';

import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

export const wrapRootElement = wrapRoot;

export const wrapPageElement = wrapPage;
