import styled from 'styled-components';

export const StyledHR = styled.hr`
  border: 0;
  height: 3px;
  width: 5em;
  min-width: 10%;
  margin: 0 auto;
  border-radius: ${props => props.theme.borderRadius.default};
  background-color: var(--color-border);
  margin-top: 3em;
  margin-bottom: 3em
`;
