import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  height: 100%;
  min-height: 10em;
  width: 100%;
  padding: 1em;
  background: var(--color-background-offset);
  box-shadow: var(--box-shadow-none);
  border: 1px solid var(--color-border);
  border-radius: ${props => props.theme.borderRadius.default};

  :hover {
    box-shadow: var(--box-shadow);

    svg {
      color: var(--color-primary);
    }
  }
`;

const CardHeading = styled.h1`
  width: 100%;
  grid-column: 1 / 2;
  align-self: center;
  color: var(--color-text);
  font-size: ${props => props.theme.fontSize.lg};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

const CardDescription = styled.h2`
  max-width: 85%;
  grid-column: 1 / -1;
  color: var(--color-text);
  font-size: ${props => props.theme.fontSize.base};
`;

const CardArrow = styled(FontAwesomeIcon)`
  align-self: center;
  justify-self: end;
  grid-column: 2 / 3;
  margin-right: 1em;

  color: var(--color-text-offset);
  font-size: ${({ theme }) => theme.fontSize.xl};
  margin: 0 ${({ theme }) => theme.spacing['2']};
`;

export function BlogCard({ title, description }) {
	return (
		<CardContainer>
			<CardHeading>{title}</CardHeading>
			<CardArrow icon="circle-right" />
			<CardDescription>{description}</CardDescription>
		</CardContainer>
	);
}

BlogCard.defaultProps = {
	title: 'Sample Title',
	description: 'Sample Description',
};

BlogCard.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
};
