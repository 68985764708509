import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background: var(--color-background-offset);
`;

export const FooterTop = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
`;

export const FooterBottom = styled.div`
  width: 100%;
  min-height: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--color-border);
  padding: 0 10%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: center;
    padding: 1em 10%;
  }
`;

export const FooterLinks = styled.ul`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  li {
    margin-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    text-decoration: none;
    color: var(--color-text);
    font-size: ${props => props.theme.fontSize.sm};
    font-weight: ${props => props.theme.fontWeight.normal};
  }

  li a:hover {
    color: var(--color-primary);
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    padding: 1em 0;
    li {
      width: 100%;
      margin: 0;
      padding: 0.5em 0;
      border-bottom: 1px solid var(--color-border);
    }
  }
`;

export const FooterEmail = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: ${props => props.theme.fontSize.sm};

  a {
    text-decoration: none;
    color: var(--color-primary);
  }

  a:hover {
    border-bottom: 1px solid var(--color-primary);
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    text-align: center;
    justify-content: center;
    padding-bottom: 1em;
    width: 100%;
  }
`;

export const CopyrightInfo = styled.div`
  max-width: 50%;
  font-size: ${({ theme }) => theme.fontSize.xs};
  letter-spacing: ${({ theme }) => theme.letterSpacing.tight};
  line-height: ${({ theme }) => theme.lineHeight.tight};

  a {
    color: var(--color-black);
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    text-align: center;
    padding-bottom: 1em;
    max-width: 100%;
  }
`;

export const IconGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 100%;
  }
`;

export const SocialIcon = styled(FontAwesomeIcon)`
  color: var(--color-text);
  font-size: ${({ theme }) => theme.fontSize.lg};
  margin: 0 ${({ theme }) => theme.spacing['2']};
`;

const NewLabel = styled.span`
  border-radius: 50em;
  padding: 0.2em 0.75em;
  margin-left: 0.5em;
  width: fit-content;
  grid-column: 1 / -2;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: ${props => props.theme.fontSize.xxs};
  background: var(--color-primary-offset);
  color: var(--color-primary);
`;

function Footer() {
	return (
		<FooterContainer>
			<FooterTop>
				<FooterLinks>
					<li>
						<Link to="/">Home</Link>
					</li>
					<li>
						<Link to="/about">About</Link>
					</li>
					<li>
						<Link to="/work">Work</Link>
					</li>
					<li>
						<Link to="/blog">
							Blog
							<NewLabel>New</NewLabel>
						</Link>
					</li>
					<li>
						<Link to="/certifications">Certifications</Link>
					</li>
					<li>
						<Link to="/contact">Contact</Link>
					</li>
				</FooterLinks>
				<FooterEmail>
					<a href="mailto:hello@marshallhahn.com">hello@marshallhahn.com</a>
				</FooterEmail>
			</FooterTop>
			<FooterBottom>
				<CopyrightInfo>
					{`Copyright © ${new Date().getFullYear()}. All Rights Reserved. `}
				</CopyrightInfo>
				<IconGroup>
					<a
						href="https://www.github.com/marshallhahn"
						target="_blank"
						rel="noreferrer"
						alt="Github"
					>
						<SocialIcon icon="fa-brands fa-github" />
					</a>
					<a
						href="https://www.linkedin.com/in/marshallhahn"
						target="_blank"
						rel="noreferrer"
						alt="LinkedIn"
					>
						<SocialIcon icon="fa-brands fa-linkedin" />
					</a>
					<a
						href="https://www.instagram.com/marshallhahn"
						target="_blank"
						rel="noreferrer"
						alt="Instagram"
					>
						<SocialIcon icon="fa-brands fa-instagram" />
					</a>
					<a
						href="https://www.threads.net/marshallhahn"
						target="_blank"
						rel="noreferrer"
						alt="Threads"
					>
						<SocialIcon icon="fa-brands fa-threads" />
					</a>
					<a
						href="https://www.twitter.com/marshallhahn"
						target="_blank"
						rel="noreferrer"
						alt="X / Twitter"
					>
						<SocialIcon icon="fa-brands fa-x-twitter" />
					</a>
				</IconGroup>
			</FooterBottom>
		</FooterContainer>
	);
}

export default Footer;
