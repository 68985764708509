import styled from 'styled-components';

export const StyledUL = styled.ul`
  padding-top: 1em;
  padding-left: 2em;
  padding-bottom: 1em;
  list-style-type: square;
  list-style-position: outside;
  line-height: 2em;
`;

export const StyledOL = styled.ol`
  padding: 1em;
  list-style-type: decimal;
  list-style-position: outside;
  line-height: 2em;
`;
