import React, { useContext } from 'react';
import styled from 'styled-components';
import { Motion, spring, presets } from 'react-motion';
import { NavbarContext } from './navbar-context';

export const HamburgerMenu = styled.span`
  display: none;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  color: var(--color-text);
  font-size: 24px;

  @media (max-width: 960px) {
    display: flex;
  }
`;

function NavbarIcon({ toggleThemePicker, setIsNavbarOpen }) {
	const { navbarIcon, setNavbarIcon } = useContext(NavbarContext);

	const handleClick = () => {
		if (!navbarIcon) {
			toggleThemePicker(false);
			setNavbarIcon(true);
			setIsNavbarOpen(true);
		} else {
			setNavbarIcon(false);
			setIsNavbarOpen(false);
		}
	};

	return (
		<HamburgerMenu>
			<svg
				viewBox="0 0 96 96"
				height="1em"
				onClick={handleClick}
				style={{
					overflow: 'visible',
					cursor: 'pointer',
					// disable touch highlighting on devices
					WebkitTapHighlightColor: 'rgba(0,0,0,0)',
				}}
			>
				<Motion
					style={{
						x: spring(navbarIcon ? 1 : 0, {
							...presets.gentle,
							precision: 0.05,
						}),
						y: spring(navbarIcon ? 0 : 1, {
							...presets.gentle,
							precision: 0.05,
						}),
					}}
				>
					{({ x, y }) => (
						<g
							id="navicon"
							fill="none"
							stroke="currentColor"
							strokeWidth="12"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<line
								transform={`translate(${x * 20}, ${x * 0}) rotate(${
									x * 45
								}, 0, 0)`}
								x1="10"
								y1="0"
								x2="90"
								y2="0"
							/>
							<line
								transform={`translate(${x * -30}, ${x * 20}) rotate(${
									x * -45
								}, 0, 0)`}
								x1="10"
								y1="70"
								x2="90"
								y2="70"
							/>
							<line
								transform={`translate(${x * 5})`}
								opacity={y}
								x1="10"
								y1="35"
								x2="90"
								y2="35"
							/>
						</g>
					)}
				</Motion>
			</svg>
		</HamburgerMenu>
	);
}

export default NavbarIcon;
