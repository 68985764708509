import React from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
	posthog.init(process.env.GATSBY_POSTHOG_KEY, {
		api_host: process.env.GATSBY_POSTHOG_HOST || 'https://app.posthog.com',
		loaded: (posthog) => {
			if (process.env.NODE_ENV === 'development') posthog.debug();
		},
	});
}

export function CSPostHogProvider({ children }) {
	return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
