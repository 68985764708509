import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CardCertificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  min-width: 18em;
  background: var(--color-background);
  box-shadow: var(--box-shadow);
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid var(--color-border);
`;

export const CardCertificationContainerPadding = styled.div`
  padding: 1em;
  width: 100%;
`;

export const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const CardLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5em;
  height: 5em;
  padding: 0.75em;
  border-radius: 50em;
  margin-right: 1em;
  border: 1px solid var(--color-border);
  background: var(--color-background-offset);
`;

export const LearningBadge = styled.div`
  font-size: ${props => props.theme.fontSize.xxs};
  text-transform: uppercase;
  height: auto;
  width: 100%;
  background: var(--color-text-offset);
  color: var(--color-text);
  text-align: center;
  border-radius: ${props => props.theme.borderRadius.sm}
    ${props => props.theme.borderRadius.sm} 0 0;
`;

export const CardToolInfo = styled.div`
  width: 50%;
`;

export const ToolName = styled.h1`
  max-width: 100%;
  color: var(--color-text);
  font-size: ${props => props.theme.fontSize.lg};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const ToolWebsiteLink = styled.a`
  font-size: ${props => props.theme.fontSize.sm};
  max-width: 100%;
  color: var(--border-offset);
  text-decoration: none;
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border: 0;
  background-color: var(--color-border);
  margin: 1.25em 0em 0em 0em;
`;

export const CertificationSubheader = styled.h3`
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.xs};
  margin: 1em 0em 0.75em 0em;
  padding: 0.5em 0em 0em 0em;
  color: var(--color-text);
`;

export const CertificationListItem = styled.li`
  margin: 1em 0em 0.5em 0.25em;
  font-size: ${props => props.theme.fontSize.sm};

  a {
    color: var(--color-text);
    text-decoration: none;
  }

  &:first-child {
    margin: 0em 0em 0.5em 0.25em;
  }

  &:last-child {
    margin: 0.5em 0em 0em 0.25em;
  }
`;

export const ExternalLinkIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin: 0 ${({ theme }) => theme.spacing['2']};
`;

export function CertificationCard({
	index, learning, logo, name, link, certifications,
}) {
	return (
		<CardCertificationContainer key={index}>
			{learning && <LearningBadge>Currently Learning</LearningBadge>}
			<CardCertificationContainerPadding>
				<CardTop>
					{logo && (
						<CardLogo>
							<img src={logo.file.url} alt={logo.description} />
						</CardLogo>
					)}
					<CardToolInfo>
						<ToolName>{name}</ToolName>
						<ToolWebsiteLink href={link} target="_blank" rel="noreferrer">
							{
								link
									.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
									.split('/')[0]
							}
						</ToolWebsiteLink>
					</CardToolInfo>
				</CardTop>
				{certifications !== null && certifications.length > 0 && (
					<>
						<Divider />
						<CertificationSubheader>Certifications:</CertificationSubheader>
						<ul>
							{certifications.map((certification, index) => (
								<CertificationListItem key={certification.name}>
									<a
										href={certification.website}
										target="_blank"
										rel="noreferrer"
									>
										{certification.name}
										<ExternalLinkIcon icon="arrow-up-right-from-square" />
									</a>
								</CertificationListItem>
							))}
						</ul>
					</>
				)}
			</CardCertificationContainerPadding>
		</CardCertificationContainer>
	);
}

CertificationCard.defaultProps = {
	name: 'Sample Tool',
	link: '/',
	certifications: [],
	learning: false,
};

CertificationCard.propTypes = {
	name: PropTypes.string,
	link: PropTypes.string,
	certifications: PropTypes.arrayOf(PropTypes.shape({
		website: PropTypes.string,
		name: PropTypes.string,
	})),
	learning: PropTypes.bool,
};
