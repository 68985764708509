import styled from 'styled-components';

export const Button = styled.a`
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  background: var(--color-primary);
  color: var(--color-white);
  padding: 1em 2em;
  border-radius: 50em;
  font-size: ${({ theme }) => theme.fontSize.xs};
  width: 100%;
`;
