import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { THEME_CONFIGS } from './configs';

export const theme = {
	font: {
		primary: 'Jost, sans-serif',
		secondary: 'serif',
	},
	fontSize: {
		xxs: '0.65rem',
		xs: '0.75rem',
		sm: '0.875rem',
		base: '1rem',
		lg: '1.125rem',
		xl: '1.25rem',
		'2xl': '1.5rem',
		'3xl': '1.875rem',
		'4xl': '2.25rem',
		'5xl': '3rem',
		'6xl': '4rem',
	},
	fontWeight: {
		hairline: '100',
		thin: '200',
		light: '300',
		normal: '400',
		medium: '500',
		semibold: '600',
		bold: '700',
		extrabold: '800',
		black: '900',
	},
	maxWidth: '580px',
	breakpoints: {
		sm: '375px',
		md: '576px',
		lg: '1023px',
		xl: '1524px',
	},
	letterSpacing: {
		tighter: '-0.05em',
		tight: '-0.025em',
		normal: '0',
		wide: '0.025em',
		wider: '0.05em',
		widest: '0.1em',
	},
	lineHeight: {
		none: '1',
		tight: '1.25',
		snug: '1.375',
		normal: '1.5',
		relaxed: '1.625',
		loose: '2',
	},
	listStyleType: {
		none: 'none',
		disc: 'disc',
		decimal: 'decimal',
	},
	spacing: {
		px: '1px',
		0: '0',
		1: '0.25rem',
		2: '0.5rem',
		3: '0.75rem',
		4: '1rem',
		5: '1.25rem',
		6: '1.5rem',
		8: '2rem',
		10: '2.5rem',
		12: '3rem',
		16: '4rem',
		20: '5rem',
		24: '6rem',
		32: '8rem',
		40: '10rem',
		48: '12rem',
		56: '14rem',
		64: '16rem',
	},
	boxShadow: {
		default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
		md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
		lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
		xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
		'2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
		inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
		outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
		none: 'none',
	},
	borderRadius: {
		none: '0',
		sm: '0.125rem',
		default: '0.25rem',
		lg: '0.5rem',
		full: '9999px',
	},
};

export const GlobalStyle = createGlobalStyle`
	${reset}

	/* use for debugging */
	/* * {
		outline: 1px solid red !important;
	} */

	*, *:before, *:after {
		box-sizing: border-box;
	}
	html {
		height: 100%;
		width: 100%;
		box-sizing: border-box;
		scroll-behavior: smooth;
		font-family: ${({ theme }) => theme.font.primary};
		font-size: ${({ theme }) => theme.fontSize.lg};
	}
	body {
		height: 100%;
		width: 100%;
		line-height: ${({ theme }) => theme.lineHeight.relaxed};
		letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
		background-color: var(--color-background);
		color: var(--color-text);
	}
	img {
		pointer-events: none;
	}
	${THEME_CONFIGS.map(config => config.buildTheme())}
`;
