/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SharedLayout } from '../shared';

const Main = styled.main`
  height: 100%;
  min-height: calc(100vh - 250px);
  align-items: start;
  justify-content: center;
`;

export function BlogLayout({ children }) {
	return (
		<SharedLayout>
			<Main>{children}</Main>
		</SharedLayout>
	);
}

BlogLayout.propTypes = {
	children: PropTypes.node.isRequired,
};
