import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SIZES = {
	xs: '3em',
	sm: '5em',
	md: '8em',
	lg: '10em',
	xl: '15em',
};

const SMALLER_SIZE = {
	sm: SIZES.xs,
	md: SIZES.sm,
	lg: SIZES.md,
	xl: SIZES.lg,
};

const AvatarImg = styled(GatsbyImage)`
  width: ${({ size }) => SIZES[size]};
  height: ${({ size }) => SIZES[size]};
  z-index: 1;
  position: relative;
  overflow: hidden;
  border-radius: 50em;
  border: 1px solid var(--color-border);
  grid-column: 1 / 3;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: ${({ size }) => SMALLER_SIZE[size]};
    height: ${({ size }) => SMALLER_SIZE[size]};
    justify-self: center;
    margin: 0 auto;
    grid-column: 1 / -1;
    grid-row: 1;
  }
`;

export function Avatar({
	src,
	size,
}) {
	const img = getImage(src);

	return (
		<AvatarImg
			image={img}
			alt="Marshall Hahn"
			size={size}
		/>
	);
}

Avatar.defaultProps = {
	src: {},
	size: 'md',
};

Avatar.propTypes = {
	src: PropTypes.shape({
		images: PropTypes.shape({
			sources: PropTypes.arrayOf(
				PropTypes.shape({
					sizes: PropTypes.string,
					srcSet: PropTypes.string,
					type: PropTypes.string,
				})
			),
			fallback: PropTypes.shape({
				src: PropTypes.string,
				srcSet: PropTypes.string,
				sizes: PropTypes.string,
			}),
		}),
		layout: PropTypes.string,
		backgroundColor: PropTypes.string,
		width: PropTypes.number,
		height: PropTypes.number,
	}),
	size: PropTypes.string,
};
