/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SharedLayout } from '../shared';

const Main = styled.main`
  height: 100%;
  min-height: calc(100vh - 250px);
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 100px));
  align-items: start;
  justify-content: center;
`;

const Wrapper = styled.div`
  grid-column-start: 2;
  grid-column-end: -2;
  margin-top: 1em;
`;

export function MainLayout({ children }) {
	return (
		<SharedLayout>
			<Main>
				<Wrapper>{children}</Wrapper>
			</Main>
		</SharedLayout>
	);
}

MainLayout.propTypes = {
	children: PropTypes.node.isRequired,
};
