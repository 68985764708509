/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Header from './components/header';
import Footer from './components/footer';
import { ThemePicker } from '../components/theme-picker';

import { CSPostHogProvider } from '../providers/posthog';

import { GlobalStyle } from '../themes';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
	faBars,
	faArrowUpRightFromSquare,
	faCircleRight,
	faPaintRoller,
	faXmark,
} from '@fortawesome/free-solid-svg-icons';

import {
	faGithub,
	faTwitter,
	faXTwitter,
	faLinkedin,
	faInstagram,
	faThreads,
} from '@fortawesome/free-brands-svg-icons';

library.add(
	faBars,
	faArrowUpRightFromSquare,
	faPaintRoller,
	faXmark,
	faCircleRight,
	faGithub,
	faTwitter,
	faXTwitter,
	faThreads,
	faLinkedin,
	faInstagram
);

export function SharedLayout({ children }) {
	const [isThemePickerOpen, setIsThemePickerOpen] = useState(false);

	const toggleThemePicker = (option) => {
		if (option !== undefined) {
			setIsThemePickerOpen(option);
		} else {
			setIsThemePickerOpen(!isThemePickerOpen);
		}
	};

	return (
		<>
			<GlobalStyle />
			<CSPostHogProvider>
				{isThemePickerOpen && (
					<ThemePicker
						toggleThemePicker={toggleThemePicker}
					/>
				)}
				<Header
					siteTitle="Marshall Hahn"
					toggleThemePicker={toggleThemePicker}
				/>
				{children}
				<Footer />
			</CSPostHogProvider>
		</>
	);
}

SharedLayout.propTypes = {
	children: PropTypes.node.isRequired,
};
