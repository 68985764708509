import styled from 'styled-components';
import { Link } from 'gatsby';

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

export const StyledLinkExternal = styled.a`
  text-decoration: none;
  color: var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
  font-size: ${({ theme }) => theme.fontSize.xs};
`;
