import { THEME_CONFIGS } from '../../themes/configs';

export const getThemes = () => THEME_CONFIGS.map(({ identifier, name, values }) => ({
	id: identifier,
	name,
	colors: [
		[values[0].key, values[0].value],
		[values[1].key, values[1].value],
	],
}));
