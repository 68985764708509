import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'gatsby-plugin-theme-switcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getThemes } from './get-themes';

const ThemePickerContainer = styled.ul`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  grid-auto-flow: column;
  align-items: center;
  width: 100%;
  max-height: calc(100px + 4em);
  overflow-y: hidden;
  overflow-x: scroll;
  background-color: var(--color-background);
  border-bottom: 1px solid var(--color-border, #FBFBFB);
  padding: 1em;
  position: relative;
`;

const ThemePickerTopBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 1em;
  padding-bottom: 0;
`;

const ThemePickerCloseButton = styled.button`
  justify-self: flex-end;
  border: 1px solid var(--color-border);
  background: var(--color-background-offset);
  border-radius: 100em;
  cursor: pointer;
  padding: 0.5em 0.75em;
  margin-left: 0.5em;
  color: var(--color-text);
  width: 2.25em;
  height: 2.25em;
  line-height: 1em;
`;

const ThemePickerCloseIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSize.sm};
`;

const ThemePickerHeading = styled.h1`
  grid-column-start: 2;
  text-align: center;
`;

const ThemePickerItem = styled.li`
  display: inline-block;
  border: 1px solid var(--color-border);
  border-radius: 0.5em;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  min-width: 175px;
  min-height: calc(100px + 1em);

  &:hover {
    transform: scale(1.05);
    box-shadow: var(--box-shadow);
    z-index: 1;
  }
`;

const ThemePickerButton = styled.button`
  display: block;
  padding: 1em;
  border-radius: 0.5em;
  border: none;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: var(--color-background);
  cursor: pointer;
`;

const ThemePickerName = styled.div`
  color: var(--color-text);
  margin-bottom: 0.5em;
`;

const ThemePickerColor = styled.span`
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-left: -6px;
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  background-color: ${props => props.color};
  border: 2px solid var(--color-border);

  :first-of-type {
    margin-left: 0;
  }
`;

export function ThemePicker({ toggleThemePicker }) {
	const { theme, switchTheme } = useContext(ThemeContext);
	const themeConfigs = getThemes();

	const selectTheme = (id) => {
		switchTheme(id);
		toggleThemePicker();
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<ThemePickerTopBar>
				<ThemePickerHeading>Select Theme</ThemePickerHeading>
				<ThemePickerCloseButton
					onClick={() => toggleThemePicker()}
				>
					<ThemePickerCloseIcon icon="xmark" />
				</ThemePickerCloseButton>
			</ThemePickerTopBar>
			<ThemePickerContainer>
				{themeConfigs.map(({ id, name, colors }) => (
					<ThemePickerItem key={id}>
						<ThemePickerButton
							className="js-themepicker-themeselect"
							data-theme={id}
							aria-label={`select color theme ${name}`}
							onClick={() => selectTheme(id)}
						>
							<ThemePickerName>{name}</ThemePickerName>
							{colors.map(color => (
								<ThemePickerColor
									key={color[0]}
									color={color[1]}
								/>
							))}
						</ThemePickerButton>
					</ThemePickerItem>
				))}
			</ThemePickerContainer>
		</>
	);
}
