import React from 'react';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import {
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
	FacebookShareButton,
	FacebookIcon,
} from 'react-share';

export const SocialShareContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  max-width: 400px;
  margin: 5em auto;
  padding: 0.5em 1em 0em 1em;
  border: 1px solid var(--color-border);
  background: var(--color-background-offset);
  box-shadow: var(--box-shadow);
`;

export function Share() {
	const { href } = useLocation();
	return (
		<SocialShareContainer>
			<LinkedinShareButton url={href}>
				<LinkedinIcon size={48} round />
			</LinkedinShareButton>
			<TwitterShareButton url={href}>
				<TwitterIcon size={48} round />
			</TwitterShareButton>
			<FacebookShareButton url={href}>
				<FacebookIcon size={48} round />
			</FacebookShareButton>
		</SocialShareContainer>
	);
}
