import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Logo } from '../logo';
import NavbarIcon from './navbar-icon';
import NavbarDropdown from './navbar-dropdown';
import { NavbarProvider } from './navbar-context';

export const NavbarContainer = styled.nav`
  min-width: 100%;
  min-height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  background: var(--color-background);
`;

export const NavbarLeft = styled.div`
  min-width: 50%;
  min-height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1em;
`;

export const NavbarRight = styled.div`
  min-width: 50%;
  min-height: 75px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1em;
`;

export const NavbarLinks = styled.ul`
  width: 75%;
  min-height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  li {
    margin-left: 1em;
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li a {
    padding: 1em;
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--color-border-none);
    text-decoration: none;
    color: var(--color-text);
    font-size: ${props => props.theme.fontSize.base};
    font-weight: ${props => props.theme.fontWeight.normal};
  }

  li a:hover {
    border-bottom: 3px solid var(--color-primary);
    margin-bottom: -3px;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const NewLabel = styled.span`
  border-radius: 50em;
  padding: 0.2em 0.75em;
  margin-left: 0.5em;
  width: fit-content;
  grid-column: 1 / -2;
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: ${props => props.theme.fontSize.xxs};
  background: var(--color-primary-offset);
  color: var(--color-primary);
`;

const ThemePickerButton = styled.button`
  border: 1px solid var(--color-border);
  background: var(--color-background-offset);
  border-radius: 100em;
  cursor: pointer;
  padding: 0.5em 0.75em;
  margin-left: 0.5em;
  color: var(--color-text);
  width: auto;
  height: 2.25em;
  line-height: 1em;

  span {
    margin-left: 0.5em;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    letter-spacing: ${({ theme }) => theme.letterSpacing.wider};
  }

  :hover {
    border: 1px solid var(--color-border)
  }
`;

const ThemePickerPaintIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSize.xxs};
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: auto;
    max-height: 50px;
    margin-top: 6px;
  }
`;

export function Navbar({ title, toggleThemePicker }) {
	const [isNavbarOpen, setIsNavbarOpen] = useState(false);

	return (
		<NavbarProvider>
			<NavbarContainer>
				<NavbarLeft>
					<LogoContainer>
						<Link to="/" title="Marshall Logomark">
							<Logo />
						</Link>
					</LogoContainer>
					{!isNavbarOpen && (
						<ThemePickerButton onClick={() => toggleThemePicker()}>
							<ThemePickerPaintIcon icon="paint-roller" />
							<span>Theme</span>
						</ThemePickerButton>
					)}
				</NavbarLeft>
				<NavbarRight>
					<NavbarIcon setIsNavbarOpen={setIsNavbarOpen} toggleThemePicker={toggleThemePicker} />
					<NavbarLinks>
						<li>
							<Link to="/about" alt="About">
								About
							</Link>
						</li>
						<li>
							<Link to="/work" alt="Work">
								Work
							</Link>
						</li>
						<li>
							<Link to="/blog" alt="Blog">
								Blog
								{' '}
								<NewLabel>New</NewLabel>
							</Link>
						</li>
						<li>
							<Link to="/certifications" alt="Certifications">
								Certifications
							</Link>
						</li>
						<li>
							<Link to="/contact" alt="Contact">
								Contact
							</Link>
						</li>
					</NavbarLinks>
				</NavbarRight>
				<NavbarDropdown setIsNavbarOpen={setIsNavbarOpen} />
			</NavbarContainer>
		</NavbarProvider>
	);
}
