import { COLOR_INDIGO, COLOR_SLATE } from '../colors';

// Color values in positions 0 and 1 will show up in Theme Picker
const MidnightThemeValues = [
	{ key: '--color-primary', value: COLOR_INDIGO[400] },
	{ key: '--color-background', value: COLOR_SLATE[900] },
	{ key: '--color-white', value: COLOR_SLATE[50] },
	{ key: '--color-gray', value: COLOR_SLATE[100] },
	{ key: '--color-black', value: COLOR_SLATE[950] },
	{ key: '--color-primary-offset', value: COLOR_INDIGO[100] },
	{ key: '--color-text', value: COLOR_SLATE[200] },
	{ key: '--color-border', value: COLOR_SLATE[700] },
	{ key: '--color-background-offset', value: COLOR_SLATE[800] },
	{ key: '--color-text-offset', value: COLOR_SLATE[500] },
	{ key: '--color-border-none', value: 'rgba(0,0,0,0)' },
	{ key: '--box-shadow', value: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 6px 0 rgba(0, 0, 0, 0.1)' },
	{ key: '--box-shadow-none', value: '0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0)' },
];

export const MidnightTheme = {
	name: 'Midnight',
	identifier: 'theme-midnight',
	values: MidnightThemeValues,
	buildTheme: () => `
		.${MidnightTheme.identifier} {
			${MidnightTheme.values.map(({ key, value }) => `${key}: ${value};`).join('\n')}
		}
	`,
};
