import React from 'react';
import { ThemeProvider } from 'styled-components';

import { MainLayout, BlogLayout, AboutLayout } from '../../layouts';
import { theme } from '../../themes';

export const wrapRootElement = ({ element }) => (
	<ThemeProvider theme={theme}>{element}</ThemeProvider>
);

export const wrapPageElement = ({ element, props }) => {
	if (props?.data?.contentfulAboutPage) {
		return <AboutLayout>{element}</AboutLayout>;
	}

	if (props?.data?.contentfulBlogPost || props?.data?.contentfulBlogCategory) {
		return <BlogLayout>{element}</BlogLayout>;
	}

	return <MainLayout>{element}</MainLayout>;
};
