import { COLOR_PRIMARY, COLOR_GRAY } from '../colors';

// Color values in positions 0 and 1 will show up in Theme Picker
const DarkThemeValues = [
	{ key: '--color-primary', value: COLOR_PRIMARY[500] },
	{ key: '--color-background', value: COLOR_GRAY[900] },
	{ key: '--color-white', value: COLOR_GRAY[50] },
	{ key: '--color-gray', value: COLOR_GRAY[100] },
	{ key: '--color-black', value: COLOR_GRAY[950] },
	{ key: '--color-primary-offset', value: COLOR_PRIMARY[300] },
	{ key: '--color-text', value: COLOR_GRAY[200] },
	{ key: '--color-border', value: COLOR_GRAY[700] },
	{ key: '--color-background-offset', value: COLOR_GRAY[800] },
	{ key: '--color-text-offset', value: COLOR_GRAY[500] },
	{ key: '--color-border-none', value: 'rgba(0,0,0,0)' },
	{ key: '--box-shadow', value: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 6px 0 rgba(0, 0, 0, 0.1)' },
	{ key: '--box-shadow-none', value: '0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0)' },
];

export const DarkTheme = {
	name: 'Dark',
	identifier: 'theme-dark',
	values: DarkThemeValues,
	buildTheme: () => `
		.${DarkTheme.identifier} {
			${DarkTheme.values.map(({ key, value }) => `${key}: ${value};`).join('\n')}
		}
	`,
};
