import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 50px;
  width: fit-content;
  background: var(--color-background-offset);
  box-shadow: var(--box-shadow);
  border-radius: 0.5em;
  border: 1px solid var(--color-border);
  margin: 1em 0em;
`;

export const BreadcrumbGroup = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0.5em;
`;

export const BreadcrumbItem = styled(Link)`
  color: var(--color-text);
  text-decoration: none;
  margin: 0em 0.5em;
`;
export const BreadcrumbSpacer = styled.p`
  color: var(--color-text);
`;

export const BreadcrumbActiveItem = styled.li`
  font-weight: 600;
  color: var(--color-primary);
  border-radius: 50em;
  margin: 0em 0.5em;
`;

export function Breadcrumbs({ main, items, activeLabel }) {
	return (
		<BreadcrumbContainer>
			<BreadcrumbGroup>
				{!main.isMain ? (
					<>
						<BreadcrumbItem to={main.link} style={{ marginLeft: '0.5em' }}>
							{main.label}
						</BreadcrumbItem>
						<BreadcrumbSpacer>/</BreadcrumbSpacer>
					</>
				) : (
					<span style={{ margin: '0 0.5em' }}>{main.label}</span>
				)}

				{items.map((item, index) => (
					<React.Fragment key={item.link}>
						<BreadcrumbItem to={item.link}>{item.label}</BreadcrumbItem>
						<BreadcrumbSpacer>/</BreadcrumbSpacer>
					</React.Fragment>
				))}

				{activeLabel && (
					<BreadcrumbActiveItem>{activeLabel}</BreadcrumbActiveItem>
				)}
			</BreadcrumbGroup>
		</BreadcrumbContainer>
	);
}

Breadcrumbs.defaultProps = {
	main: { label: 'Home', link: '/', isMain: false },
	items: [],
	activeLabel: '',
};

Breadcrumbs.propTypes = {
	main: PropTypes.shape({
		label: PropTypes.string,
		link: PropTypes.string,
		isMain: PropTypes.bool,
	}),
	items: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		link: PropTypes.string,
	})),
	activeLabel: PropTypes.string,
};
