import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { NavbarContext } from './navbar-context';

export const NavbarDropdownContainer = styled.div`
  display: none;

  @media (max-width: 960px) {
    display: flex;
    width: 100%;
    position: fixed;
    top: 75px;
    background: var(--color-background);
    box-shadow: 0px 0.25em 0.25em rgba(0, 0, 0, 0.1);
  }
`;

export const NavbarItems = styled.ol`
  border-bottom: 1px solid var(--color-border);

  & > * {
    border-bottom: 1px solid var(--color-border);
  }

  & > *:last-child {
    border-bottom: none;
  }

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    height: auto;
    width: 100%;
  }
`;

export const NavbarLink = styled(Link)`
  @media (max-width: 960px) {
    text-align: center;
    padding: 0.5em 0;
    width: 100%;
    font-size: 1.5em;
    color: var(--color-text);
    text-decoration: none;

    :hover {
      color: var(--color-primary);
    }
  }
`;

function NavbarDropdown({ setIsNavbarOpen }) {
	const { navbarIcon, setNavbarIcon } = useContext(NavbarContext);

	const handleClick = () => {
		setNavbarIcon(false);
		setIsNavbarOpen(false);
	};

	return (
		<NavbarDropdownContainer>
			{navbarIcon && (
				<NavbarItems>
					<NavbarLink to="/about" alt="About" onClick={handleClick}>
						About
					</NavbarLink>
					<NavbarLink to="/work" alt="Work" onClick={handleClick}>
						Work
					</NavbarLink>
					<NavbarLink to="/blog" alt="Blog" onClick={handleClick}>
						Blog
					</NavbarLink>
					<NavbarLink
						to="/certifications"
						alt="Certifications"
						onClick={handleClick}
					>
						Certifications
					</NavbarLink>
					<NavbarLink to="/contact" alt="Contact" onClick={handleClick}>
						Contact
					</NavbarLink>
				</NavbarItems>
			)}
		</NavbarDropdownContainer>
	);
}

export default NavbarDropdown;
