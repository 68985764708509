import React from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import { Img as RemotionImg } from 'remotion';

const StyledImage = styled(GatsbyImage)`
  box-shadow: ${props => props.theme.boxShadow.lg};
  border-radius: ${props => props.theme.borderRadius.lg};
  border: 1px solid var(--color-border);
  margin-top: 1em;
  margin-bottom: 1em;
`;

const StyledGif = styled(RemotionImg)`
  box-shadow: ${props => props.theme.boxShadow.lg};
  border-radius: ${props => props.theme.borderRadius.lg};
  border: 1px solid var(--color-border);
  margin-top: 1em;
  margin-bottom: 1em;
`;

function GifImg({ src }) {
	const href = `https:${src}`;
	return <StyledGif src={href} />;
}

export function Image({
	type = '', url = '', data = {}, description = '',
}) {
	const src = getImage(data);

	if (src === undefined && type === 'image/gif') {
		return <GifImg src={url} />;
	}

	return (
		<StyledImage
			image={src}
			alt={description}
			style={{
				width: 'fit-content',
			}}
			imgStyle={{
				objectFit: 'contain',
				objectPosition: '0% 30%',
			}}
		/>
	);
}
