import React from 'react';

export function Logo() {
	return (
		<svg
			width="100"
			height="100"
			viewBox="0 0 100 100"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M72.6163 26.2V25H53.3413L42.0913 58.675L26.9413
            25H7.74131V26.2C9.46631 26.2 12.3913 28.9 14.5663
            32.575V67.75C14.5663 73.15 12.2413 77.8 9.24131
            77.8V79H22.5913V77.8C19.5913 77.8 17.2663 73.15 17.2663
            67.75V38.2L35.2663 79H38.1163L53.3413 33.55V67.75C53.3413 73.15
            51.0163 77.8 48.0163 77.8V79H72.6163V77.8C69.6163 77.8 67.2913
            73.15 67.2913 67.75V36.25C67.2913 30.85 69.6163 26.2 72.6163
            26.2ZM84.7517 79.75C88.2767 79.75 91.0517 76.9 91.0517 73.3C91.0517
            69.925 88.3517 67.15 84.7517 67.15C81.1517 67.15 78.3017 69.925
            78.3017 73.3C78.3017 76.9 81.1517 79.75 84.7517 79.75Z"
				fill="var(--color-text, #000000)"
			/>
		</svg>
	);
}
