import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  min-height: 2em;
  height: auto;
  padding: 0.5em 1em;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  border-radius: 50em;
  color: var(--color-primary-offset);
  background: var(--color-primary);
  font-size: ${props => props.theme.fontSize.sm};

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding: 1em 1em;
    min-height: 4em;
    height: auto;
    flex-direction: column;
    line-height: 0.5em;
    max-width: calc(100% - 10%);
  }
`;

const BannerMessage = styled.div`
  margin-right: 1em;
  word-wrap: break-word;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    text-align: center;
    margin-bottom: 1em;
  }
`;

const BannerLink = styled(Link)`
  color: var(--color-primary-offset);
  font-size: ${({ theme }) => theme.fontSize.xs};
  text-transform: uppercase;
`;

const LinkArrow = styled(FontAwesomeIcon)`
  align-self: center;
  justify-self: end;
  grid-column: 2 / 3;
  margin-right: 1em;

  color: var(--color-primary-offset);
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin: 0 ${({ theme }) => theme.spacing['1']};
`;

export function Banner({
	link, message, linkMessage, arrow,
}) {
	return (
		<BannerContainer>
			{message && <BannerMessage>{message}</BannerMessage>}
			<BannerLink to={link}>
				{linkMessage}
				{arrow && <LinkArrow icon="circle-right" />}
			</BannerLink>
		</BannerContainer>
	);
}

Banner.defaultProps = {
	message: 'Banner Text',
	linkMessage: 'View More',
	link: '/',
	arrow: false,
};

Banner.propTypes = {
	message: PropTypes.string,
	linkMessage: PropTypes.string,
	link: PropTypes.string,
	arrow: PropTypes.bool,
};
