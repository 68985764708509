import React, { useState, useMemo, createContext } from 'react';

export const NavbarContext = createContext();

export function NavbarProvider({ children }) {
	const [navbarIcon, setNavbarIcon] = useState(false);
	const icon = useMemo(() => ({ navbarIcon, setNavbarIcon }), [navbarIcon]);

	return (
		<NavbarContext.Provider value={icon}>
			{children}
		</NavbarContext.Provider>
	);
}
